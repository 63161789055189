import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePT from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import Counter from './counter';
import { ArrayTypeComponent } from './formly/array.type';
import { MultiSchemaTypeComponent } from './formly/multischema.type';
import { NullTypeComponent } from './formly/null.type';
import { ObjectTypeComponent } from './formly/object.type';
import {
  constValidationMessage,
  exclusiveMaximumValidationMessage,
  exclusiveMinimumValidationMessage,
  maxItemsValidationMessage,
  maxValidationMessage,
  maxlengthValidationMessage,
  minItemsValidationMessage,
  minValidationMessage,
  minlengthValidationMessage,
  multipleOfValidationMessage,
} from './formly/validators';
import { MaxLengthValidator } from './formly/validators/max-length.validator';
import { NotFutureDateValidator } from './formly/validators/not-future-date.validator';
import { AngularCoreSharedModule } from './shared/angular-core-shared.module';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderModule } from './shared/header/header.module';

registerLocaleData(localePT, 'pt');

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ArrayTypeComponent,
    ObjectTypeComponent,
    MultiSchemaTypeComponent,
    NullTypeComponent,
  ],
  imports: [
    HeaderModule,
    MatMenuModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularCoreSharedModule,
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    NgxMaskModule.forRoot(maskConfig),
    ReactiveFormsModule,
    FormlyMatDatepickerModule,
    QuillModule.forRoot({
      customModules: [
        {
          implementation: Counter,
          path: 'modules/counter',
        },
      ],
      customOptions: [
        {
          import: 'formats/font',
          whitelist: [
            'mirza',
            'roboto',
            'aref',
            'serif',
            'sansserif',
            'monospace',
          ],
        },
      ],
    }),
    FormlyModule.forRoot({
      extras: { resetFieldOnHide: true },
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'null', message: 'should be null' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: 'multipleOf', message: multipleOfValidationMessage },
        {
          name: 'exclusiveMinimum',
          message: exclusiveMinimumValidationMessage,
        },
        {
          name: 'exclusiveMaximum',
          message: exclusiveMaximumValidationMessage,
        },
        { name: 'minItems', message: minItemsValidationMessage },
        { name: 'maxItems', message: maxItemsValidationMessage },
        { name: 'uniqueItems', message: 'should NOT have duplicate items' },
        { name: 'const', message: constValidationMessage },
        { name: 'notFutureDate', message: 'A data não pode ser futura' },
        {
          name: 'maxLengthValidator',
          message: 'O campo não aceita mais de 255 caracteres',
        },
      ],
      validators: [
        { name: 'notFutureDate', validation: NotFutureDateValidator },
        { name: 'maxlengthValidator', validation: MaxLengthValidator },
      ],
      types: [
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        { name: 'single-choice', extends: 'radio' },
        { name: 'boolean', extends: 'checkbox' },
        { name: 'enum', extends: 'select' },
        {
          name: 'null',
          component: NullTypeComponent,
          wrappers: ['form-field'],
        },
        { name: 'array', component: ArrayTypeComponent },
        { name: 'object', component: ObjectTypeComponent },
      ],
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    HttpClientModule,
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
})
export class AppModule { }
